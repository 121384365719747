import { defineStore, acceptHMRUpdate } from 'pinia'

export const useFlowControlsStore = defineStore('flowControls', {
	state: () => ({
		fcpComplete: false,
		vueGtagInstall: false,
		ipAddress: undefined
	}),
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useFlowControlsStore, import.meta.hot))
}

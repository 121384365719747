<template>
  <div class="x-d-flex x-flex-wrap x-justify-content-between x-align-items-center py-3 px-3 nav-wrapper">
    <router-link to="/">
      <div class="logo-card">
        <img :src="logoUrl" alt="" width="88" height="30"/>
      </div>
    </router-link>
    <div class="menu x-d-flex x-align-items-center x-col-auto">
      <router-link to="/about" class="item">
        <span class="text-truncate">About Us</span>
      </router-link>
      <span class="text-truncate item" @click="signIn('top-navigation')">Sign In</span>
      <span class="text-truncate item sign-in" @click="registerByEvent('top-navigation')">Sign Up</span>
    </div>
  </div>
</template>

<script>
import {defineComponent, getCurrentInstance, ref} from 'vue'

export default defineComponent({
  name: 'TopNavigation',
  setup() {
    const {proxy: {$emitter}} = getCurrentInstance()
    const logoUrl = ref(new URL('@/assets/images/logo.svg', import.meta.url))

    const registerByUrl = (msg) => {
      $emitter.emit('register.by.url', msg)
    }
    const signIn = (msg) => {
      $emitter.emit('sign.in', msg)
    }

    const registerByEvent = (msg) => {
      $emitter.emit('register', msg)
    }

    return {
      logoUrl,
      registerByEvent,
      registerByUrl,
      signIn,
    }
  },
})
</script>
<style lang="scss" scoped>

.sign-in {
  color: #3a36db;
  border: 1px solid #3a36db;
  border-radius: 10px;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.nav-wrapper {
  gap: 1rem;
}

.x-flex-wrap {
  flex-wrap: wrap !important;
}

.x-justify-content-between {
  justify-content: space-between !important;
}

.x-d-flex {
  display: flex;
}

.x-align-items-center {
  align-items: center;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu {
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130%;
  margin-top: 5px;
  gap: 1rem;

  .item {
    &.sign-in {
      color: #3a36db;
      border: 1px solid #3a36db;
      border-radius: 10px;
      font-weight: 500;
      padding: 0.5rem 1rem;
      @media (max-width: 575px) {
        padding: 0.25rem 0.5rem;
      }
    }
  }

  @media (max-width: 575px) {
    font-size: 16px;
  }

  @media (max-width: 370px) {
    font-size: 13px;
    gap: 0.75rem;
  }
  @media (max-width: 330px) {
    font-size: 12px;
  }
}
</style>

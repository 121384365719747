import { getFCP } from 'web-vitals'
import { stanAnalytics } from './stan-analytics'
import { requestIdleCallback } from './global'

export default function (router) {
	// Report site loading statistics to analytics
	function sendMetric(payload) {
		stanAnalytics('fcp', {
			meta: { username: payload.context.username },
			props: {
				routeName: payload.context.routeName,
				value: parseInt(payload.metric.value, 10),
			},
		})
	}

	const asyncWebVitals = async name => {
		const context = {
			routeName: name,
			username: 'guest',
		}
		try {
			getFCP(metric => {
				sendMetric({ context, metric })
			})
		} catch (error) {
			console.error('[Analytics]', error)
		}
	}

	router.afterEach(async route => {
		requestIdleCallback(() => {
			asyncWebVitals(route.name)
		})
	})
}

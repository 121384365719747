import { h, ref, defineComponent, markRaw, getCurrentInstance, computed, watch, onMounted, nextTick, Transition, onBeforeUnmount } from 'vue'
import { client } from '@/plugins/platform'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from '@/components/SDialog/bodyScrollLock'
// import './SDialog.scss'

export const createComponent = raw => markRaw(defineComponent(raw))

export function hSlot(slot, otherwise) {
	return slot !== void 0 ? slot() || otherwise : otherwise
}

const handlers = []
let escDown

let lastKeyCompositionStatus = false

export function onKeyDownComposition(evt) {
	lastKeyCompositionStatus = evt.isComposing === true
}

export function shouldIgnoreKey(evt) {
	return lastKeyCompositionStatus === true || evt !== Object(evt) || evt.isComposing === true || evt.qKeyEvent === true
}

export function isKeyCode(evt, keyCodes) {
	return shouldIgnoreKey(evt) === true ? false : [].concat(keyCodes).includes(evt.keyCode)
}

function onKeydown(evt) {
	escDown = evt.keyCode === 27
}

function onBlur() {
	if (escDown === true) {
		escDown = false
	}
}

function onKeyup(evt) {
	if (escDown === true) {
		escDown = false

		if (isKeyCode(evt, 27) === true) {
			handlers[handlers.length - 1](evt)
		}
	}
}

function update(action) {
	window[action]('keydown', onKeydown)
	window[action]('blur', onBlur)
	window[action]('keyup', onKeyup)
	escDown = false
}

export function addEscapeKey(fn) {
	if (client.is.desktop === true) {
		handlers.push(fn)

		if (handlers.length === 1) {
			update('addEventListener')
		}
	}
}

export function removeEscapeKey(fn) {
	const index = handlers.indexOf(fn)
	if (index > -1) {
		handlers.splice(index, 1)

		if (handlers.length === 0) {
			update('removeEventListener')
		}
	}
}

let queue = []
let waitFlags = []

export function addFocusFn(fn) {
	if (waitFlags.length === 0) {
		fn()
	} else {
		queue.push(fn)
	}
}

export function removeFocusFn(fn) {
	queue = queue.filter(entry => entry !== fn)
}

export function useModelToggle({ showing, canShow, handleShow, handleHide, processOnMount }) {
	const vm = getCurrentInstance()
	const { props, emit, proxy } = vm

	const showRegisterDlg = computed({
		get: () => props.modelValue,
		set: value => emit('update:modelValue', value),
	})

	let payload

	function show(evt) {
		if (props.disable === true || (evt !== void 0 && evt.qAnchorHandled === true) || (canShow !== void 0 && canShow(evt) !== true)) {
			return
		}

		const listener = props['onUpdate:modelValue'] !== void 0
		if (listener === true) {
			emit('update:modelValue', true)
			payload = evt
			nextTick(() => {
				if (payload === evt) {
					payload = void 0
				}
			})
		}
		if (props.modelValue === null || listener === false) {
			showRegisterDlg.value = true
			processShow(evt)
		}
	}

	function hide(evt) {
		if (props.disable === true) {
			return
		}
		const listener = props['onUpdate:modelValue'] !== void 0
		if (listener === true) {
			emit('update:modelValue', false)
			payload = evt
			nextTick(() => {
				if (payload === evt) {
					payload = void 0
				}
			})
		}

		if (props.modelValue === null || listener === false) {
			showRegisterDlg.value = false
			processHide(evt)
		}
	}

	function toggle(evt) {
		if (showing.value === true) {
			hide(evt)
		} else {
			show(evt)
		}
	}

	function processShow(evt) {
		if (showing.value === true) {
			return
		}
		showing.value = true

		if (handleShow !== void 0) {
			handleShow(evt)
		} else {
			emit('show', evt)
		}
	}

	function processHide(evt) {
		if (showing.value === false) {
			return
		}

		showing.value = false

		if (handleHide !== void 0) {
			handleHide(evt)
		} else {
			emit('hide', evt)
		}
	}

	function processModelChange(val) {
		if (props.disable === true && val === true) {
			if (props['onUpdate:modelValue'] !== void 0) {
				emit('update:modelValue', false)
			}
		} else if ((val === true) !== showing.value) {
			const fn = val === true ? processShow : processHide
			fn(payload)
		}
	}

	watch(() => props.modelValue, processModelChange)

	processOnMount === true &&
		onMounted(() => {
			processModelChange(props.modelValue)
		})

	const publicMethods = { show, hide, toggle }
	Object.assign(proxy, publicMethods)
	return publicMethods
}

export default createComponent({
	name: 'SDialog',
	inheritAttrs: false,
	props: {
		modelValue: Boolean,
		transitionDuration: {
			type: [String, Number],
			default: 300,
		},
		transitionShow: String,
		transitionHide: String,
		noBackdropDismiss: Boolean,
		noShake: Boolean,
		noRouteDismiss: Boolean,
		autoClose: Boolean,
	},
	emits: ['click', 'escape-key', 'dialog-show'],
	setup(props, { slots, emit, attrs }) {
		const vm = getCurrentInstance()
		const innerRef = ref(null)
		const showing = ref(false)
		const transitionState = ref(false)
		const animating = ref(false)

		let shakeTimeout,
			refocusTarget = null

		const hideOnRouteChange = computed(() => props.noRouteDismiss !== true)

		const { hide } = useModelToggle({
			showing,
			hideOnRouteChange,
			handleShow,
			handleHide,
			processOnMount: true,
		})

		const classes = computed(
			() => 's-dialog__inner flex no-pointer-events q-dialog__inner--minimized q-dialog__inner--standard fixed-full flex-center'
		)

		const rootClasses = computed(() => ['s-dialog s-dialog--modal fullscreen no-pointer-events ', attrs.class])

		const useBackdrop = computed(() => showing.value === true)

		const transitionShow = computed(() => 's-transition--' + (props.transitionShow === void 0 ? 'scale' : props.transitionShow))

		const transitionHide = computed(() => 's-transition--' + (props.transitionHide === void 0 ? 'scale' : props.transitionHide))

		const transition = computed(() => (transitionState.value === true ? transitionHide.value : transitionShow.value))

		const transitionStyle = computed(() => `--s-transition-duration: ${props.transitionDuration}ms`)

		function focus(selector) {
			addFocusFn(() => {
				let node = innerRef.value

				if (node === null || node.contains(document.activeElement) === true) {
					return
				}

				node = node.querySelector(selector || '[autofocus], [data-autofocus]') || node
				node.focus({ preventScroll: true })
			})
		}

		function shake() {
			focus()
			const node = innerRef.value
			if (node !== null) {
				node.classList.remove('s-animate--scale')
				node.classList.add('s-animate--scale')
				clearTimeout(shakeTimeout)
				shakeTimeout = setTimeout(() => {
					if (innerRef.value !== null) {
						node.classList.remove('s-animate--scale')
						focus()
					}
				}, 170)
			}
		}

		function onEscapeKey() {
			hide()
		}

		function onBackdropClick(e) {
			if (props.persistent !== true && props.noBackdropDismiss !== true) {
				hide(e)
			} else if (props.noShake !== true) {
				shake()
			}
		}

		function handleShow(evt) {
			emit('dialog-show', evt)
			nextTick(() => {
				const dialogWraper = document.querySelector('.scrollTarget')
				disableBodyScroll(dialogWraper)
			})
		}

		function handleHide(evt) {
			nextTick(() => {
				clearAllBodyScrollLocks()
			})
		}

		function cleanup(hiding) {
			clearTimeout(shakeTimeout)
		}

		function onAutoClose(e) {
			hide(e)
			emit('click', e)
		}

		const onEvents = computed(() => {
			return props.autoClose === true ? { onClick: onAutoClose } : {}
		})

		onBeforeUnmount(cleanup)

		watch(showing, val => {
			nextTick(() => {
				transitionState.value = val
			})
		})

		watch(useBackdrop, val => {
			if (val === true) {
				// addFocusout(onFocusChange)
				addEscapeKey(onEscapeKey)
			} else {
				// removeFocusout(onFocusChange)
				removeEscapeKey(onEscapeKey)
			}
		})

		const innerDialog = computed(() => {
			return showing.value === true
				? h(
						'div',
						{
							ref: innerRef,
							class: classes.value,
							style: transitionStyle.value,
							tabindex: -1,
							...onEvents.value,
						},
						[hSlot(slots.default)]
				  )
				: null
		})

		const backdropDialog = computed(() => {
			return useBackdrop.value === true
				? h('div', {
						class: 's-dialog__backdrop fixed-full',
						style: transitionStyle.value,
						'aria-hidden': 'true',
						onMousedown: onBackdropClick,
				  })
				: null
		})

		function renderDialog() {
			return h('div', { class: rootClasses.value }, [
				h(Transition, { name: 's-transition--fade', appear: true }, backdropDialog.value),
				h(Transition, { name: transition.value, appear: true }, innerDialog.value),
			])
		}

		return () => renderDialog()
	},
})

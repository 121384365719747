<template>
  <div ref="youTubeVideoPlayer" class="x-modal-wraper">
    <div ref="youTubeVideoPlayerBody" class="x-modal-body x-ratio">
      <div id="youtube-player-wraper-id" class="youtube-player" data-id="Gwm_Odue2gc">
        <div id="video-poster-backdoor" class="art-poster-backdoor"></div>
        <div id="video-poster-src"
             v-show="!isPlayStarted"
             class="art-poster"
             :style="videoPosterStyle">
        </div>
      </div>
    </div>
    <div class="close-dialog-wraper" @click="cmdCloseRegisterDlg">
      <div class="close-dialog" @click="cmdCloseRegisterDlg"></div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onBeforeUnmount, onMounted, ref} from 'vue'
import {useCookies} from 'vue3-cookies'
import {stanAnalytics} from '@/plugins/stan-analytics'

export default defineComponent({
  name: 'YouTubeVideo',
  props: {
    videoId: {
      type: String,
      default() {
        return ''
      },
    }
  },
  emits: ['close'],
  setup(props, {emit}) {
    const youTubeVideoPlayer = ref(null)
    const youTubeVideoPlayerBody = ref(null)
    const isPlayStarted = ref(false)
    const videoID = ref(props.videoId)
    isPlayStarted.value = true

    const {cookies} = useCookies()

    const cmdCloseRegisterDlg = () => {
      emit('close')
    }

    const stanAnalyticsCall = async (name, section) => {
      const props = {
        pageVersion: cookies.get('landingVersion'),
        section: section,
      }
      stanAnalytics(name, {meta: {username: 'guest'}, props})
    }

    function frameLoadedLoaded() {
      const backdoor = document.getElementById('video-poster-backdoor')
      setTimeout(() => {
        backdoor.style.opacity = 0
      }, 1200)
      isPlayStarted.value = true
    }

    function loadIframe() {
      document.addEventListener('message', (msg) => {
        console.log('loadIframe message', msg)
      }, false)
      const div = document.getElementById('youtube-player-wraper-id')
      const divPreview = document.getElementById('video-poster-src')
      const iframe = document.createElement('iframe')
      iframe.setAttribute('src', `https://www.youtube.com/embed/${videoID.value}?autoplay=1&rel=0`)
      iframe.setAttribute('onload', frameLoadedLoaded())
      iframe.setAttribute('frameborder', '0')
      iframe.setAttribute('allowfullscreen', '1')
      iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture')
      div.appendChild(iframe)
    }

    const togglePlay = () => {
      stanAnalyticsCall('landing-watch-demo-play', '')
      loadIframe()
    }

    const videoPosterStyle = computed(() => {
      return `background-image:url(//i.ytimg.com/vi/${videoID.value}/hqdefault.jpg)`
    })

    onMounted(async () => {
      loadIframe()
      // setTimeout(() => { loadIframe() }, 1500)
      await nextTick()
    })

    return {
      youTubeVideoPlayer,
      youTubeVideoPlayerBody,
      cmdCloseRegisterDlg,
      isPlayStarted,
      togglePlay,
      videoPosterStyle
    }
  }
})
</script>
<style lang="scss">
.x-modal-wraper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: inherit;
  margin: auto;
  width: 1048px;
  height: 590px;
  overflow: hidden;

  .x-modal-body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
  }
}

.close-dialog-wraper {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 50;

  .close-dialog {
    position: absolute;
    opacity: 0.6;
    top: 10px;
    right: 20px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &:before,
    &:after {
      position: absolute;
      content: ' ';
      height: 18px;
      width: 2px;
      background-color: #000;
      filter: invert(100%);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.close-dialog-wraper:hover * {
  opacity: 1;
}

// .x-modal-wraper { & > div { border: 1px solid #000000; } }

@media (max-width: 1048px) {
  .x-modal-wraper {
    // & > div { background-color: #000000; }
    width: 100%;
    height: auto;

    .x-modal-body {
      height: auto;
    }
  }
}

.x-ratio {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    padding-top: 56.25%;
    content: '';
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (min-aspect-ratio: 16/10) and (max-width: 1048px) {
  .x-modal-wraper {
    width: 90%;
  }
}

@media (min-aspect-ratio: 16/9) and (max-width: 1048px) {
  .x-modal-wraper {
    width: 80%;
  }
}

.youtube-player {
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: #000000;
  }

  .art-poster-backdoor {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 40;
    opacity: 1;
    background-color: black;
  }

  .art-poster {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 10;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
  }

  .art-mask {
    display: flex;
    align-items: center;
    bottom: 0;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    pointer-events: all;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 15;

    .art-state {
      align-items: center;
      display: flex;
      height: 60px;
      justify-content: center;
      position: absolute;
      width: 60px;
      opacity: 0.8 !important;
      right: auto !important;
      bottom: auto !important;
    }
  }

  .art-icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    line-height: 1.5;

    svg {
      fill: #000;
    }
  }
}
</style>

import axios from 'axios'

// eslint-disable-next-line import/no-mutable-exports
const configSettings = {
	siteName: 'Stan',
	nodeEnvironment: process.env.NODE_ENV,
	analyticsApi: process.env.VUE_APP_ANALYTICS_API,
	adminUrl: process.env.VUE_APP_ADMIN_URL,
	pythonApi: process.env.VUE_APP_API,
	defaultStoreUri: process.env.VUE_APP_STORE_URL,
	experimentPriceId: process.env.VUE_APP_STAN_EXPERIMENT_PRICE_ID,
	experimentPrice: process.env.VUE_APP_STAN_EXPERIMENT_PRICE,
	environment: process.env.VUE_APP_ENV_NAME,
}
axios.defaults.baseURL = configSettings.pythonApi
// eslint-disable-next-line import/prefer-default-export
export { configSettings }

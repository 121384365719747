export function ready(fn) {
	window.addEventListener('load', async () => {
		await fn()
	})
}

export function readyDomContentLoad(fn) {
	if (document.addEventListener) {
		document.addEventListener(
			'DOMContentLoaded',
			() => {
				fn()
			},
			false
		)
	} else if (document.attachEvent) {
		document.attachEvent('onreadystatechange', () => {
			if (document.readyState === 'complete') {
				fn()
			}
		})
		if (document.documentElement.doScroll && typeof window.frameElement === 'undefined') {
			try {
				document.documentElement.doScroll('left')
			} catch (error) {
				return setTimeout(() => {
					console.log('error: ', error)
				}, 20)
			}
			fn()
		}
	}
	document.onreadystatechange = () => {
		if (document.readyState === 'complete') {
			// HOW LOAD COMPONENTS HERE?
			this.readyStateComplete = true
		}
	}
}

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useCookies } from 'vue3-cookies'
import VueGtag, { config } from 'vue-gtag'
import mitt from 'mitt'
import App from './App.vue'
import router from './router'
import { useFlowControlsStore } from '@/stores/flowControls'
import { requestIdleCallback } from '@/plugins/global'
import { stanAnalytics } from '@/plugins/stan-analytics'
import { configSettings } from './plugins/config-settings'
import axios from 'axios'
import '@/assets/styles/variables.scss'
import TopNavigation from './components/TopNavigation'
import FooterNavigation from './components/FooterNavigation'
import CONSTANTS from './helpers/constants'
import VueGtm from 'vue-gtm'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(router)
const emitter = mitt()
app.config.globalProperties.$emitter = emitter
app.provide('configSettings', configSettings)
app.provide('_stan_app_emitter_', emitter)

app.component('TopNavigation', TopNavigation)
app.component('FooterNavigation', FooterNavigation)

const { cookies } = useCookies()
app.provide('cookies', cookies)
app.provide('stanAnalytics', stanAnalytics)

const fcStore = useFlowControlsStore()
fcStore.$reset()
fcStore.$subscribe(mutation => {
	if (mutation?.payload?.fcpComplete && !fcStore.vueGtagInstall) {
		requestIdleCallback(() => {
			app.use(
				VueGtag,
				{
					includes: [{ id: CONSTANTS.GOOGLE_AD_CONVERSION_ID, params: { send_page_view: false } }],
					config: { id: CONSTANTS.GOOGLE_GA4_MEASUREMENT_ID, params: { send_page_view: false } },
					enabled: configSettings.nodeEnvironment === 'production',
					onReady() {
						const adminDomain = configSettings.adminUrl.split('://')[1]
						const landingDomain = configSettings.defaultStoreUri.split('://')[1]
						config({
							linker: {
								domains: [adminDomain, landingDomain],
								accept_incoming: true,
								url_position: 'query',
							},
						})
					},
				},
				router
			)
			fcStore.$patch({ vueGtagInstall: true })
		})
	}
})

axios
	.get('v1/get-ip')
	.then(response => {
		fcStore.$patch({ ipAddress: response.data.ip })
	})
	.catch(err => {
		console.warn(err)
	})

app.use(VueGtm, {
		id: CONSTANTS.GOOGLE_TAG_MANAGER_ID,
		enabled: process.env.VUE_APP_ENV_NAME === 'production',
		debug: true,
		vueRouter: router 
})


app.mount('#app')

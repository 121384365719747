/* eslint-disable no-sequences */
/* eslint-disable block-scoped-var */
/* eslint-disable prefer-template */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-multi-assign */
/* eslint-disable no-undef */
// eslint-disable-next-line import/named

function InitializeTrackers() {
	if (process.env.VUE_APP_ENV_NAME === 'production') {
		// Facebook Pixel
		!(function(f, b, e, v, n, t, s) {
			if (f.fbq) return
			n = f.fbq = function() {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
			}
			if (!f._fbq) f._fbq = n
			n.push = n
			n.loaded = !0
			n.version = '2.0'
			n.queue = []
			t = b.createElement(e)
			t.async = !0
			t.src = v
			s = b.getElementsByTagName(e)[0]
			s.parentNode.insertBefore(t, s)
		})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

		fbq('init', '4296631060464751')
		fbq('track', 'PageView')

		!(function(w, d, t) {
			w.TiktokAnalyticsObject = t
			var ttq = (w[t] = w[t] || [])
			;(ttq.methods = [
				'page',
				'track',
				'identify',
				'instances',
				'debug',
				'on',
				'off',
				'once',
				'ready',
				'alias',
				'group',
				'enableCookie',
				'disableCookie',
			]),
				(ttq.setAndDefer = function(t, e) {
					t[e] = function() {
						t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
					}
				})
			for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i])
			;(ttq.instance = function(t) {
				for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n])
				return e
			}),
				(ttq.load = function(e, n) {
					var i = 'https://analytics.tiktok.com/i18n/pixel/events.js'
					;(ttq._i = ttq._i || {}),
						(ttq._i[e] = []),
						(ttq._i[e]._u = i),
						(ttq._t = ttq._t || {}),
						(ttq._t[e] = +new Date()),
						(ttq._o = ttq._o || {}),
						(ttq._o[e] = n || {})
					var o = document.createElement('script')
					;(o.type = 'text/javascript'), (o.async = !0), (o.src = i + '?sdkid=' + e + '&lib=' + t)
					var a = document.getElementsByTagName('script')[0]
					a.parentNode.insertBefore(o, a)
				})

			ttq.load('CCMTOGBC77U6HJLVI9U0')
			ttq.load('CEEG70RC77UA21H9VOB0')
			ttq.page()
		})(window, document, 'ttq')

		sessionStorage.setItem('initializedPixels', '1')
	}
}

export const AdsTracking = (task, event = '') => {
	if (task === 'Initialize') {
		InitializeTrackers()
	}
}

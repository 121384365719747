<template>
	<div class="x-row x-mx-0 pb-2">
		<div class="x-col-12 x-col-lg-4 x-d-flex x-flex-wrap x-justify-content-between x-align-items-center">
			<div>
				<img :src="logoUrl" alt="" width="88" height="30" />
			</div>
			<div>
				<a v-for="(social, i) in socials" :href="social.url" target="_blank" :key="`social-${i}`">
					<img :src="social.image" :alt="social.name" class="x-mx-2" style="width: 31px" width="31" height="31" />
				</a>
			</div>
		</div>
		<div class="x-col-12 x-col-lg-8">
			<div class="x-d-lg-none divider x-my-4"></div>
			<div class="x-d-flex x-flex-wrap x-justify-content-lg-end x-justify-content-between x-mt-2">
				<a :href="link.href" target="_blank" class="link x-mb-2" v-for="(link, i) in links" :key="`link-${i}`">{{ link.name }}</a>
			</div>
		</div>
	</div>
</template>
<script>
	import { defineComponent, ref } from 'vue'

	const socials = [
		{
			name: 'Instagram',
			url: 'https://www.instagram.com/stanforcreators',
			image: new URL('@/assets/images/home/socials/instagram.svg', import.meta.url),
		},
		{
			name: 'Tiktok',
			url: 'https://www.tiktok.com/@stanforcreators',
			image: new URL('@/assets/images/home/socials/tiktok.svg', import.meta.url),
		},
		{
			name: 'LinkedIn',
			url: 'https://www.linkedin.com/company/stanwithme',
			image: new URL('@/assets/images/home/socials/Linkedin.svg', import.meta.url),
		},
		{
			name: 'Twitter',
			url: 'https://twitter.com/stanforcreators',
			image: new URL('@/assets/images/home/socials/twitter.svg', import.meta.url),
		},
		{
			name: 'Youtube',
			url: 'https://www.youtube.com/channel/UCQ1wox_PP5Lds__fQdy6NvQ',
			image: new URL('@/assets/images/home/socials/youtube.svg', import.meta.url),
		},
	]
	const links = [
		{ name: 'Sign In', href: 'https://admin.stanwith.me/auth' },
		{ name: 'Privacy Policy', href: 'https://assets.stanwith.me/legal/privacy-policy.pdf' },
		{ name: 'Terms and Conditions', href: 'https://assets.stanwith.me/legal/terms-of-service.pdf' },
		{ name: 'Help', href: 'https://help.stan.store' },
		{
			name: 'Join the Stan Affiliate Program',
			href: 'https://help.stan.store/article/89-referral-program-overview',
		},
	]
	export default defineComponent({
		name: 'FooterNavigation',
		setup() {
			const logoUrl = ref(new URL('@/assets/images/logo.svg', import.meta.url))
			return {
				logoUrl,
				socials,
				links,
			}
		},
	})
</script>

<style lang="scss" scoped>
	.link {
		color: #48638c;
		font-size: 14px;
		font-weight: 400;
		padding-left: 10px;
		padding-right: 10px;

		@media (max-width: 687px) {
			padding-left: 0;
			padding-right: 0;
			min-width: 120px;
		}
	}

	.divider {
		border-bottom: 1px solid #cad1e1;
	}
</style>
